import React from 'react'
import { Link } from 'gatsby'

import { Button } from 'bloomer'

import styled from 'styled-components'

const CustomParagraph = styled.p`
  color: #666e70;
  padding-top: 9px;
`
const CustomH1 = styled.h1`
  font-size: 160px;
  line-height: 1.3em;
  margin-bottom: 0;
`
const CustomNotFound = styled.p`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 30px;
`

const CustomButton = styled(Button)`
  margin-top: 10px;
  background-color: #fec256;
`
const CustomLink = styled(Link)`
  color: #13253b;
  font-size: 1rem;
`

const NotFoundPage = () => (
  <>
    <section className="hero">
      <div className="hero-body">
        <div className="container has-text-centered">
          <CustomH1 className="title">404</CustomH1>
          <CustomNotFound>Page not Found!</CustomNotFound>
          <CustomParagraph>
            We're sorry, the page you requested cannot be found.
            <br />
            You can go back to
          </CustomParagraph>
          <CustomButton isSize="medium">
            <CustomLink to="/">Homepage</CustomLink>
          </CustomButton>
        </div>
      </div>
    </section>
  </>
)

export default NotFoundPage
